import * as React from "react"
import { GatsbyLinkProps } from "gatsby-link"
import { Card, List } from "semantic-ui-react"
import { MarkdownRemarkGroupConnection } from "../../generated/graphql-types"

interface TagsCardProps extends React.HTMLProps<HTMLDivElement> {
  tags: MarkdownRemarkGroupConnection[]
  Link: React.ComponentClass<GatsbyLinkProps<any>>
  tag?: string
}

const TagCard = (props: TagsCardProps) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Tags</Card.Header>
      </Card.Content>
      <Card.Content>
        <List>
          {props.tags.map(tag => {
            if (tag.fieldValue === undefined || tag.fieldValue === null) {
              throw new ErrorEvent("OOPS!")
            }
            const isActive = tag.fieldValue === props.tag
            const activeStyle = {
              fontWeight: "700",
            }
            const tagLink = isActive ? `/blog` : `/blog/tags/${tag.fieldValue}/`
            return (
              <List.Item as="span" key={tag.fieldValue}>
                <List.Icon name="tag" color={isActive ? "blue" : undefined} />
                <List.Content style={isActive ? activeStyle : null}>
                  <props.Link to={tagLink}>
                    {tag.fieldValue} ({tag.totalCount})
                  </props.Link>
                </List.Content>
              </List.Item>
            )
          })}
        </List>
      </Card.Content>
    </Card>
  )
}

export default TagCard
